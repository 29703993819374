import React, { useContext, useState } from "react";
import { Controller } from "react-hook-form";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { SelectBox } from "../../components/SelectBox";
import { useSelector } from "react-redux";
import ValidWhatsappSvg from "../../assets/img/svg/ValidWhatsappSvg";
import { TextButton } from "../../components/TextButton";
import * as TextBoxSize from "../../components/TextBox";
import setSelectValues from "../../utils/setSelectValues";
import { useIntl } from "react-intl";
import { EDIT_CONTACT, removeField } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { EDIT } from "../../constants";
import { KIND } from "../../components/IconButton";
import ValidCallSvg from "../../assets/img/svg/ValidCallSvg";
import SmsContactSvg from "../../assets/img/svg/SmsContactSvg";
import Delete from "../../assets/img/svg/CustomerOverViewIcon/DairyNote/Delete";
import SMS from "../../assets/img/svg/SMS";
import DeleteIcon from "../../assets/img/svg/DeleteIcon";
import { Icon, Typography } from "../../components_v2";
import { PLACEMENT, StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { TextArea } from "../../components/TextArea";
import { SIZE } from "baseui/textarea";
import _ from "lodash";
import useFormat from "../../hooks/useFormat";
import ContactStatusPopover from "./ContactStatusPopOver/ContactStatusPopOver";
import getSelectValues from "../../utils/getSelectValues";
import VoiceBotIcon from "../../assets/img/svg/VoiceBotIcon";

function PhoneInputFields({
  errors,
  setValue,
  i,
  e,
  actionType,
  findFullWidth,
  control,
  isDisable,
  remove,
  fields,
}) {
  const { referenceData, defaultData } = useContext(MaxyfiContext);
  const [isSMSField, setIsSMSField] = useState(false);
  const [deleteComment, setDeleteComment] = useState(false);
  const [commentOpen, setCommentOpen] = useState(false);
  const format = useFormat();
  const customerData = useSelector((e) => e.customerOverviewDrawer);
  //Whatsup
  const [isWhatsupStatus, setWhatsupStatus] = useState(e.whatsapp_status);
  const [isVoiceStatus, setVoiceStatus] = useState(e.ac_sts);
  //callStatus
  const [isCallStatus, setIsCallStatus] = useState([
    {
      id: _.get(e, "call_status", ""),
      label: _.get(e, "call_status", ""),
    },
  ]);
  //sms Status
  const [isSmsStatus, setSmsStatus] = useState(e.sms_status);
  const [submitCallStatus, setSubmitCallStatus] = useState(false);
  const [submitSmsStatus, setSubmitSmsStatus] = useState(false);
  const [submitWhatsup, setSubmitWhatsupStatus] = useState(false);
  function close() {
    setIsSMSField(!isSMSField);
  }
  const intl = useIntl();

  //Call status
  const handleCallStatusChange = (field, status) => {
    const newValue = [{ id: status?.toUpperCase(), label: status }];

    setIsCallStatus(newValue);
    field.onChange(newValue);
    setValue(field.name, newValue);
  };

  const handleCallSave = (field) => {
    setSubmitSmsStatus(true);
    setValue(field.name, field.value);
  };

  //Sms Save
  const handleSmsStatusChange = (field, status) => {
    const newValue = [{ id: status, label: status }];

    setSmsStatus(newValue);
    field.onChange(newValue);
  };

  const handleSmsSave = (field) => {
    setSubmitCallStatus(true);
    setValue(field.name, field.value);
  };

  //Whatsup status

  const handleWhatsupStatusChange = (field, status) => {
    const newValue = [{ id: status, label: status }];

    setWhatsupStatus(newValue);
    field.onChange(newValue);
  };
  const handleVoiceStatusChange = (field, status) => {
    const newValue = [{ id: status, label: status }];

    setVoiceStatus(newValue);
    field.onChange(newValue);
  };

  const handleWhatsupSave = (field) => {
    setSubmitWhatsupStatus(true);
    setValue(field.name, field.value);
  };
  const [textCommentOpen, setTextCommentOpen] = useState(false);
  const handleDeleteComment = (val) => {
    setValue(val, "");
  };
  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        {/* <div style={{ width: "100px" }}> */}
        <Controller
          name={`phone[${i}].type`}
          control={control}
          defaultValue={[{ id: "PERSONAL_MOBILE", label: "Personal" }]}
          render={({ field }) => (
            <div style={{ width: "130px" }}>
              <SelectBox
                {...field}
                disabled={isDisable}
                clearable={false}
                name={field.name}
                size={TextBoxSize.SIZE.mini}
                error={
                  errors &&
                  errors["phone"] &&
                  errors["phone"][i] &&
                  errors["phone"][i].type &&
                  errors["phone"][i].type.message
                }
                label={intl.formatMessage({
                  id: `type_contact`,
                })}
                placeholder={intl.formatMessage({
                  id: "type_contact",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["contact_phone_type"]}
              />
            </div>
          )}
        />
        <Controller
          name={`phone[${i}].country_code`}
          control={control}
          defaultValue={
            actionType === EDIT && !e.phone_code && e.value
              ? ""
              : setSelectValues(defaultData.phone_code)
            // : actionType === EDIT
            // ? setSelectValues(defaultData.phone_code)
            // setSelectValues(defaultData.phone_code)
          }
          rules={{ required: "Required" }}
          render={({ field }) => (
            <div
              style={{ width: "85px" }}
              onClick={() => {
                if (findFullWidth && findFullWidth.current) {
                  findFullWidth.current.scrollTop = 50;
                }
              }}
            >
              <SelectBox
                {...field}
                disabled={isDisable}
                clearable={false}
                name={field.name}
                size={TextBoxSize.SIZE.mini}
                error={
                  errors &&
                  errors["phone"] &&
                  errors["phone"][i] &&
                  errors["phone"][i].country_code &&
                  errors["phone"][i].country_code.message
                }
                label={intl.formatMessage({
                  id: "code",
                })}
                placeholder={intl.formatMessage({
                  id: "code",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["contact_phone_code"]}
              />
            </div>
          )}
        />
        {/* </div> */}
        <Controller
          name={`phone[${i}].value`}
          control={control}
          render={({ field }) => (
            <>
              <TextBoxSize.TextBox
                type="number"
                {...field}
                disabled={isDisable}
                fullWidth
                name={field.name}
                size={TextBoxSize.SIZE.mini}
                error={
                  errors &&
                  errors["phone"] &&
                  errors["phone"][i] &&
                  errors["phone"][i].value &&
                  errors["phone"][i].value.message
                }
                label={intl.formatMessage({
                  id: "number",
                })}
                placeholder={intl.formatMessage({
                  id: "enter_number",
                })}
                value={field.value}
                onKeyDown={(e) => {
                  if (e.key == "ArrowUp" || e.key == "ArrowDown") {
                    e.preventDefault();
                  }
                }}
              />
            </>
          )}
        />
        <div style={{ width: "180px" }}>
          <Controller
            name={`phone[${i}].status`}
            control={control}
            defaultValue={[{ id: "VALID", label: "Valid" }]}
            render={({ field }) => (
              <SelectBox
                {...field}
                disabled={
                  e && e.status && e?.status[0] && e?.status[0].id === "DNC"
                    ? true
                    : false
                }
                clearable={false}
                name={field.name}
                size={TextBoxSize.SIZE.mini}
                error={
                  errors &&
                  errors["phone"] &&
                  errors["phone"][i] &&
                  errors["phone"][i].status &&
                  errors["phone"][i].status.message
                }
                label={intl.formatMessage({
                  id: `status`,
                })}
                placeholder={intl.formatMessage({
                  id: "status",
                })}
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.value);
                }}
                options={referenceData["contact_status"]}
              />
            )}
          />
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "3px",
            gap: "5px",
          }}
        >
          {e.call_status && (
            <StatefulPopover
              content={({ close }) => (
                <Controller
                  name={`phone[${i}].call_status`}
                  control={control}
                  defaultValue={[{ id: "VALID", label: "Valid" }]}
                  render={({ field }) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "10px",
                      }}
                    >
                      <Typography
                        type="p"
                        className="regular"
                        subType="regular"
                      >
                        Call Status
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        {["Valid", "Invalid", "DNC"].map((status) => (
                          <div
                            key={status}
                            style={{
                              border: `1px solid ${
                                isCallStatus[0]?.id === status?.toUpperCase()
                                  ? "#516BEB"
                                  : "#CDCED9"
                              }`,
                              pointerEvents:
                                _.get(e, "call_status", "") === "DNC"
                                  ? "none"
                                  : "auto",
                              padding: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleCallStatusChange(field, status)
                            }
                          >
                            <Typography
                              type="p"
                              className="regular"
                              style={{
                                color: `${
                                  isCallStatus[0]?.id === status?.toUpperCase()
                                    ? "#516BEB"
                                    : ""
                                }`,
                              }}
                            >
                              {status}
                            </Typography>
                          </div>
                        ))}
                      </div>
                      <div style={{ border: "1px solid #EEEEEE" }}> </div>
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <TextButton
                          size="mini"
                          kind={KIND.tertiary}
                          type="button"
                          onClick={() => {
                            setSubmitCallStatus(false);
                            close();
                          }}
                        >
                          Cancel
                        </TextButton>
                        <TextButton
                          size="mini"
                          disabled={
                            _.get(e, "call_status", "") === "DNC" ? true : false
                          }
                          kind={KIND.primary}
                          type="button"
                          onClick={() => {
                            handleCallSave(field);
                            close();
                          }}
                        >
                          Save
                        </TextButton>
                      </div>
                    </div>
                  )}
                />
              )}
              returnFocus
              autoFocus
              overrides={{
                Arrow: {
                  style: ({ $theme }) => ({
                    outline: `#CDCED9 solid`,
                    backgroundColor: "#16164B",
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    width: "158px",
                    height: "112px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "5px",
                  }),
                },
              }}
              placement={PLACEMENT.bottomLeft}
            >
              <div style={{ cursor: "pointer" }} onClick={() => close()}>
                <ValidCallSvg
                  color={
                    isCallStatus[0]?.id === "VALID" ? "#12b112" : "#FD372A"
                  }
                />
              </div>
            </StatefulPopover>
            // <ContactStatusPopover
            //   control={control}
            //   control_name={`phone[${i}].call_status`}
            //   isStatusChanged={isCallStatus}
            //   status_name={"Call Status"}
            //   handleStatusChange={handleCallStatusChange}
            //   handleSave={handleCallSave}
            //   setSubmitStatus={setSubmitCallStatus}
            //   IconComponent={<ValidCallSvg />}
            // />
          )}
          {e.sms_status && (
            <StatefulPopover
              content={({ close }) => (
                <Controller
                  name={`phone[${i}].sms_status`}
                  control={control}
                  defaultValue={[{ id: "VALID", label: "Valid" }]}
                  render={({ field }) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "10px",
                      }}
                    >
                      <Typography
                        type="p"
                        className="regular"
                        subType="regular"
                      >
                        SMS Status
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        {["VALID", "INVALID", "DNC"].map((status) => (
                          <div
                            key={status}
                            style={{
                              border: `1px solid ${
                                getSelectValues(isSmsStatus) === status
                                  ? "#516BEB"
                                  : "#CDCED9"
                              }`,
                              pointerEvents:
                                getSelectValues(
                                  _.get(e, "sms_status", []),
                                  false
                                ) === "DNC"
                                  ? "none"
                                  : "auto",
                              padding: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleSmsStatusChange(field, status)}
                          >
                            <Typography
                              type="p"
                              className="regular"
                              style={{
                                color: `${
                                  getSelectValues(isSmsStatus) === status
                                    ? "#516BEB"
                                    : ""
                                }`,
                              }}
                            >
                              {status === "DNC" ? status : _.capitalize(status)}
                            </Typography>
                          </div>
                        ))}
                      </div>
                      <div style={{ border: "1px solid #EEEEEE" }}> </div>
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <TextButton
                          size="mini"
                          kind={KIND.tertiary}
                          type="button"
                          onClick={() => {
                            setSubmitSmsStatus(false);
                            close();
                          }}
                        >
                          Cancel
                        </TextButton>
                        <TextButton
                          size="mini"
                          disabled={
                            getSelectValues(_.get(e, "sms_status", [])) ===
                            "DNC"
                              ? true
                              : false
                          }
                          kind={KIND.primary}
                          type="button"
                          onClick={() => {
                            handleSmsSave(field);
                            close();
                          }}
                        >
                          Save
                        </TextButton>
                      </div>
                    </div>
                  )}
                />
              )}
              returnFocus
              autoFocus
              overrides={{
                Arrow: {
                  style: ({ $theme }) => ({
                    outline: `#CDCED9 solid`,
                    backgroundColor: "#16164B",
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    width: "158px",
                    height: "112px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "5px",
                  }),
                },
              }}
              placement={PLACEMENT.bottomLeft}
            >
              <div style={{ cursor: "pointer" }} onClick={() => close()}>
                <SMS
                  width={19}
                  height={24}
                  color={
                    getSelectValues(isSmsStatus) === "VALID"
                      ? "#12b112"
                      : "#FD372A"
                  }
                />
              </div>
            </StatefulPopover>
          )}

          {e.whatsapp_status && (
            <StatefulPopover
              content={({ close }) => (
                <Controller
                  name={`phone[${i}].whatsapp_status`}
                  control={control}
                  defaultValue={[{ id: "VALID", label: "Valid" }]}
                  render={({ field }) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "10px",
                      }}
                    >
                      <Typography
                        type="p"
                        className="regular"
                        subType="regular"
                      >
                        Whatsapp Status
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        {["VALID", "INVALID", "DNC"].map((status) => (
                          <div
                            key={status}
                            style={{
                              border: `1px solid ${
                                getSelectValues(isWhatsupStatus) === status
                                  ? "#516BEB"
                                  : "#CDCED9"
                              }`,
                              pointerEvents:
                                getSelectValues(
                                  _.get(e, "whatsapp_status", []),
                                  false
                                ) === "DNC"
                                  ? "none"
                                  : "auto",
                              padding: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleWhatsupStatusChange(field, status)
                            }
                          >
                            <Typography
                              type="p"
                              className="regular"
                              style={{
                                color: `${
                                  getSelectValues(isWhatsupStatus) === status
                                    ? "#516BEB"
                                    : ""
                                }`,
                              }}
                            >
                              {status === "DNC" ? status : _.capitalize(status)}
                            </Typography>
                          </div>
                        ))}
                      </div>
                      <div style={{ border: "1px solid #EEEEEE" }}> </div>
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <TextButton
                          size="mini"
                          kind={KIND.tertiary}
                          type="button"
                          onClick={() => {
                            setSubmitWhatsupStatus(false);
                            close();
                          }}
                        >
                          Cancel
                        </TextButton>
                        <TextButton
                          size="mini"
                          disabled={
                            getSelectValues(_.get(e, "whatsapp_status", [])) ===
                            "DNC"
                              ? true
                              : false
                          }
                          kind={KIND.primary}
                          type="button"
                          onClick={() => {
                            handleWhatsupSave(field);
                            close();
                          }}
                        >
                          Save
                        </TextButton>
                      </div>
                    </div>
                  )}
                />
              )}
              returnFocus
              autoFocus
              overrides={{
                Arrow: {
                  style: ({ $theme }) => ({
                    outline: `#CDCED9 solid`,
                    backgroundColor: "#16164B",
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    width: "158px",
                    height: "112px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "5px",
                  }),
                },
              }}
              placement={PLACEMENT.bottomLeft}
            >
              <div style={{ cursor: "pointer" }} onClick={() => close()}>
                <ValidWhatsappSvg
                  color={
                    getSelectValues(isWhatsupStatus) === "VALID"
                      ? "#12b112"
                      : "#FD372A"
                  }
                />
              </div>
            </StatefulPopover>
          )}
          {e.ac_sts && (
            <StatefulPopover
              content={({ close }) => (
                <Controller
                  name={`phone[${i}].ac_sts`}
                  control={control}
                  defaultValue={[{ id: "VALID", label: "Valid" }]}
                  render={({ field }) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "10px",
                      }}
                    >
                      <Typography
                        type="p"
                        className="regular"
                        subType="regular"
                      >
                        VoiceBot Status
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                        }}
                      >
                        {["VALID", "INVALID", "DNC"].map((status) => (
                          <div
                            key={status}
                            style={{
                              border: `1px solid ${
                                getSelectValues(isVoiceStatus) === status
                                  ? "#516BEB"
                                  : "#CDCED9"
                              }`,
                              pointerEvents:
                                getSelectValues(
                                  _.get(e, "ac_sts", []),
                                  false
                                ) === "DNC"
                                  ? "none"
                                  : "auto",
                              padding: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleVoiceStatusChange(field, status)
                            }
                          >
                            <Typography
                              type="p"
                              className="regular"
                              style={{
                                color: `${
                                  getSelectValues(isVoiceStatus) === status
                                    ? "#516BEB"
                                    : ""
                                }`,
                              }}
                            >
                              {status === "DNC" ? status : _.capitalize(status)}
                            </Typography>
                          </div>
                        ))}
                      </div>
                      <div style={{ border: "1px solid #EEEEEE" }}> </div>
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        <TextButton
                          size="mini"
                          kind={KIND.tertiary}
                          type="button"
                          onClick={() => {
                            setSubmitWhatsupStatus(false);
                            close();
                          }}
                        >
                          Cancel
                        </TextButton>
                        <TextButton
                          size="mini"
                          disabled={
                            getSelectValues(_.get(e, "whatsapp_status", [])) ===
                            "DNC"
                              ? true
                              : false
                          }
                          kind={KIND.primary}
                          type="button"
                          onClick={() => {
                            handleWhatsupSave(field);
                            close();
                          }}
                        >
                          Save
                        </TextButton>
                      </div>
                    </div>
                  )}
                />
              )}
              returnFocus
              autoFocus
              overrides={{
                Arrow: {
                  style: ({ $theme }) => ({
                    outline: `#CDCED9 solid`,
                    backgroundColor: "#16164B",
                  }),
                },
                Inner: {
                  style: ({ $theme }) => ({
                    width: "158px",
                    height: "112px",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "5px",
                  }),
                },
              }}
              placement={PLACEMENT.bottomLeft}
            >
              <div style={{ cursor: "pointer" }} onClick={() => close()}>
                <VoiceBotIcon
                  color={
                    getSelectValues(isVoiceStatus) === "VALID"
                      ? "#12b112"
                      : "#FD372A"
                  }
                />
              </div>
            </StatefulPopover>
          )}
          <StatefulPopover
            content={({ close }) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {e.comment ? (
                  <></>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      gap: "4px",
                      margin: "4px 0px",
                      // padding: "6px 0px 6px 0px",
                    }}
                    onClick={() => {
                      setCommentOpen(true);
                      close();
                    }}
                  >
                    <div style={{ paddingLeft: "5px" }}>
                      <Icon icon="contact_chat_icon" />
                    </div>

                    <Typography
                      type="p"
                      subType="medium"
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      Add Comment
                    </Typography>
                  </div>
                )}

                {fields.length > 1 && (
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      margin: "4px 0px",
                      // padding: "8px 0px 8px 0px",

                      ":hover": {
                        background: "#ffdfdc",
                      },
                    }}
                    onClick={() => {
                      remove(i);
                    }}
                  >
                    <div style={{ paddingLeft: "5px" }}>
                      <Icon icon="delete_outline" isPressable color="#FD372A" />
                    </div>

                    <Typography
                      type="p"
                      subType="medium"
                      style={{ color: "#FD372A" }}
                    >
                      Delete
                    </Typography>
                  </div>
                )}
              </div>
            )}
            returnFocus
            autoFocus
            triggerType={TRIGGER_TYPE.hover}
            overrides={{
              Arrow: {
                style: ({ $theme }) => ({
                  outline: `#CDCED9 solid`,
                  backgroundColor: "#16164B",
                }),
              },
              Inner: {
                style: ({ $theme }) => ({
                  width: "120px",
                  // height: "70px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "5px",
                }),
              },
            }}
            placement={PLACEMENT.leftBottom}
          >
            <Icon icon="triple_dot" isPressable />
          </StatefulPopover>
        </div>
      </div>
      {actionType === "EDIT_CONTACT" ? (
        <>
          {deleteComment ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "5px",
                  alignItems: "flex-start",
                }}
              >
                {e.comment && !textCommentOpen && <Icon icon="comment_sep" />}

                {e.comment && !textCommentOpen && (
                  <>
                    {!textCommentOpen && (
                      <div
                        style={{
                          display: "flex",
                          backgroundColor: "#F0F5FF",
                          flexDirection: "column",
                          width: "497px",
                          padding: "13px",
                          borderRadius: "6px",
                          gap: "8px",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography type="p" className="regular">
                          {_.get(e, "comment", "")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                gap: "6px",
                              }}
                              onClick={() => {
                                setTextCommentOpen(true);
                              }}
                            >
                              <Icon
                                icon="edit_outline"
                                color="#787878"
                                size={20}
                                isPressable
                              />
                              <Typography
                                type="p"
                                className="text-secondary"
                                subType="regular"
                              >
                                Edit
                              </Typography>
                            </div>
                            <Icon icon="dot" color="#D9D9D9" size={6} />
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "2px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setDeleteComment(true);

                                handleDeleteComment(`phone[${i}].comment`);
                              }}
                            >
                              <Icon
                                icon="delete_outline"
                                color="#787878"
                                isPressable
                              />
                              <Typography
                                type="p"
                                className="text-secondary"
                                subType="regular"
                              >
                                Remove
                              </Typography>
                            </div>
                          </div>
                          {_.get(e, "aby", "") ? (
                            <div
                              style={{
                                display: "flex",
                                gap: "2px",
                                alignItems: "center",
                              }}
                            >
                              <Icon icon="new_user_outLine" color="#787878" />
                              <Typography
                                type="p"
                                className="text-secondary"
                                subType="regular"
                              >
                                {format.rd({
                                  id: _.get(e, "aby", ""),
                                  name: "users",
                                })}{" "}
                                , {format.date({ value: _.get(e, "aat", "") })}
                              </Typography>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}

                {!e.comment && commentOpen && !textCommentOpen && (
                  <Icon icon="comment_sep" />
                )}
                {!e.comment && commentOpen && !textCommentOpen && (
                  <Controller
                    name={`phone[${i}].comment`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextArea
                          onFocus={false}
                          {...field}
                          name={field.name}
                          // disabled={}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "Comments",
                          })}
                          placeholder={intl.formatMessage({
                            id: "Status Comments",
                          })}
                          size={SIZE.compact}
                          value={field.value}
                          // disabled={pageType == VIEW ? true : false}
                          // maxLength={200}
                        />
                      </>
                    )}
                  />
                )}
                {textCommentOpen ? (
                  <Controller
                    name={`phone[${i}].comment`}
                    control={control}
                    render={({ field }) => (
                      <>
                        <TextArea
                          onFocus={false}
                          {...field}
                          name={field.name}
                          // disabled={}
                          error={
                            errors[field.name] && errors[field.name].message
                          }
                          label={intl.formatMessage({
                            id: "Comments",
                          })}
                          placeholder={intl.formatMessage({
                            id: "Status Comments",
                          })}
                          size={SIZE.compact}
                          value={field.value}
                          // disabled={pageType == VIEW ? true : false}
                          // maxLength={200}
                        />
                      </>
                    )}
                  />
                ) : (
                  <></>
                )}
              </div>

              {textCommentOpen && (
                <div
                  className="comment_cancel_button_container"
                  onClick={() => {
                    setTextCommentOpen(false);
                    setValue(`phone[${i}].comment`, e?.comment);
                  }}
                >
                  <Icon icon="crosss" isPressable />
                  <Typography
                    type="p"
                    className="text-secondary"
                    subType="medium"
                  >
                    {" "}
                    Cancel
                  </Typography>
                </div>
              )}
              {!e.comment && commentOpen && !textCommentOpen && (
                <div
                  className="comment_cancel_button_container"
                  onClick={() => {
                    setTextCommentOpen(false);
                    setCommentOpen(false);
                    setValue(`phone[${i}].comment`, "");
                  }}
                >
                  <Icon icon="crosss" isPressable />
                  <Typography
                    type="p"
                    className="text-secondary"
                    subType="medium"
                  >
                    {" "}
                    Cancel
                  </Typography>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {commentOpen && (
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "flex-start",
              }}
            >
              <Icon icon="comment_sep" />
              <Controller
                name={`phone[${i}].comment`}
                control={control}
                render={({ field }) => (
                  <>
                    <TextArea
                      onFocus={false}
                      {...field}
                      name={field.name}
                      // disabled={}
                      error={errors[field.name] && errors[field.name].message}
                      label={intl.formatMessage({
                        id: "Comments",
                      })}
                      placeholder={intl.formatMessage({
                        id: "Status Comments",
                      })}
                      size={SIZE.compact}
                      value={field.value}
                      // disabled={pageType == VIEW ? true : false}
                      // maxLength={200}
                    />
                  </>
                )}
              />
            </div>
          )}
          {commentOpen && (
            <div
              className="comment_cancel_button_container"
              onClick={() => {
                setCommentOpen(false);
                setValue(`phone[${i}].comment`, "");
              }}
            >
              <Icon icon="crosss" isPressable />
              <Typography type="p" className="text-secondary" subType="medium">
                {" "}
                Cancel
              </Typography>
            </div>
          )}
        </>
      )}

      {/* {isSMSField && (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <div style={{ width: "180px" }}>
            <Controller
              name={`phone[${i}].call_status`}
              control={control}
              defaultValue={[{ id: "VALID", label: "Valid" }]}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  disabled={
                    e &&
                    e.call_status &&
                    e?.call_status[0] &&
                    e?.call_status[0].id === "DNC"
                      ? true
                      : false
                  }
                  clearable={false}
                  name={field.name}
                  size={TextBoxSize.SIZE.mini}
                  error={
                    errors &&
                    errors["phone"] &&
                    errors["phone"][i] &&
                    errors["phone"][i].status &&
                    errors["phone"][i].status.message
                  }
                  label={intl.formatMessage({
                    id: `call_status`,
                  })}
                  placeholder={intl.formatMessage({
                    id: "call_status",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["contact_comm_status"]}
                />
              )}
            />
          </div>
          <div style={{ width: "180px" }}>
            <Controller
              name={`phone[${i}].sms_status`}
              control={control}
              defaultValue={[{ id: "VALID", label: "Valid" }]}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  disabled={
                    e &&
                    e.sms_status &&
                    e?.sms_status[0] &&
                    e?.sms_status[0].id === "DNC"
                      ? true
                      : false
                  }
                  clearable={false}
                  name={field.name}
                  size={TextBoxSize.SIZE.mini}
                  error={
                    errors &&
                    errors["phone"] &&
                    errors["phone"][i] &&
                    errors["phone"][i].status &&
                    errors["phone"][i].status.message
                  }
                  label={intl.formatMessage({
                    id: `sms_status`,
                  })}
                  placeholder={intl.formatMessage({
                    id: "sms_status",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["contact_comm_status"]}
                />
              )}
            />
          </div>

          <div style={{ width: "180px" }}>
            <Controller
              name={`phone[${i}].whatsapp_status`}
              control={control}
              defaultValue={[{ id: "VALID", label: "Valid" }]}
              render={({ field }) => (
                <SelectBox
                  {...field}
                  disabled={
                    e &&
                    e.whatsapp_status &&
                    e?.whatsapp_status[0] &&
                    e?.whatsapp_status[0].id === "DNC"
                      ? true
                      : false
                  }
                  clearable={false}
                  name={field.name}
                  size={TextBoxSize.SIZE.mini}
                  error={
                    errors &&
                    errors["phone"] &&
                    errors["phone"][i] &&
                    errors["phone"][i].status &&
                    errors["phone"][i].status.message
                  }
                  label={intl.formatMessage({
                    id: `whatsapp_status`,
                  })}
                  placeholder={intl.formatMessage({
                    id: "whatsapp_status",
                  })}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.value);
                  }}
                  options={referenceData["contact_comm_status"]}
                />
              )}
            />
          </div>
        </div>
      )} */}
    </>
  );
}

export default PhoneInputFields;
