import React, { useContext, useState } from "react";
import Layout from "../../layouts";
import PageTitle from "../../components/PageTitle";
import DownloadOverview from "../../containers/CustomerSummary/DownloadOverview";
import TableViewPicker from "../../components/TableViewPicker";
import CustomerOverviewTable from "../../containers/CustomerSummary/CustomerOverviewTable";
import { useSelector, useDispatch } from "react-redux";
import {
  getInitialCustomerSummary,
  setCusSumOrgData,
  setCustomerSummaryPageIndex,
  setCustomerSummaryTableView,
} from "../../redux/customerSummary/action";
import DownloadSummary from "../../components/DownloadSummary/DownloadSummary";
import { customerData } from "../../services/customerSummary";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import CustomerOverview from "../CustomerOverview/CustomerOverview";
import { CUSTOMER_OVERVIEW, CUSTOMER_OVERVIEW_COUNT } from "../../constants";
import { MaxyfiContext } from "../../providers/MaxyfiProvider";
import { useQuery } from "react-query";
import CustomerListTable from "../../containers/CustomerSummary/CustomerListTable";
import AssignData from "../../components/AssignData/AssignData";
import UploadDataModal from "../../components/UploadDataModal/UploadDataModal";
import { KIND, SIZE, TextButton } from "../../components/TextButton";
import AddCustomer from "../../components/AddCustomer/AddCustomer";
import {
  addingInvoiceDocumentData,
  documentInvoiceAddingDrawerState,
} from "../../redux/invoiceAdding/dmsInvoiceAdding/action";
import DMSystem from "../../containers/CustomerOverView/DMSystem";
import { createDocument } from "../../services/documentSystem";
import AddNewInvoicingForm from "../../containers/Invoicing/AddNewInvoicingForm";
import RBACWrapper from "../../providers/RBACProvider/RBACWrapper";
import {
  ADD_CUSTOMER,
  BULK_RM_WORKFLOW,
  DOWNLOAD_CUSTOMER_LISIT,
  DOWNLOAD_DIALER_LIST,
} from "../../providers/RBACProvider/permissionList";
import RoleAccessSelect from "../../components/RoleAccessSelect/RoleAccessSelect";
import { useEffect } from "react";
import moment from "moment";
import dynamicQueryFilter from "../../utils/dynamicQueryFilter";
import Loader from "../../components/Loader";
function CustomerSummary() {
  const dispatch = useDispatch();
  const {
    tableDraftView,
    viewName,
    tableCurrentState,
    gridStructure,
    gridAndViewLoaded = false,
  } = useSelector((state) => state.customerSummary);
  const { addingInvoiceDrawerState, addingInvoiceType } = useSelector(
    (s) => s.invoiceAddingDocumentReducer
  );
  let globalFilter = tableCurrentState.globalFilters;

  // let params = new URLSearchParams(window.location.search);

  let params = useParams();

  const [querySortBy, setQuerySortBy] = useState("");
  const [queryFilter, setQueryFilter] = useState({});
  const [getSearchParams, setGetSearchParams] = useSearchParams();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState("");

  const getParams = getSearchParams.get("customer");
  const getInvoiceParams = getSearchParams.get("invoice");
  const getType = getSearchParams.get("type");

  // TableStateColumn
  let reduxData = useSelector((state) => state.customerSummary.tableState);
  let hiddenColData = useSelector(
    (state) => state.customerSummary.tableDraftView.table
  );
  let currentTableState = useSelector(
    (state) => state.customerSummary.tableCurrentState
  );

  let removingExpanderSelection = reduxData.columns.filter(
    (i) => i.id != "expander" && i.id != "selection"
  );

  let {
    currentOrganization,
    userPersonalization,
    currentDefaultFormatDetails,
    appAccessRest,
  } = useContext(MaxyfiContext);

  console.log(
    "currentDefaultFormatDetails",
    currentDefaultFormatDetails.en_sort
  );

  useEffect(() => {
    dispatch(setCusSumOrgData(currentDefaultFormatDetails));
  }, []);

  let allColumns = removingExpanderSelection.map((i) => i);

  let hiddenColumns = hiddenColData
    .filter((i) => !i.is_hidden)
    .sort((a, b) => a.order - b.order)
    .map((i) => i.col_id);

  let filteredColumns = hiddenColumns
    .map((e) => {
      let column = allColumns.find((col) => col.path === e);

      if (column && column.path) {
        return column;
      }
    })
    .filter((e) => e);

  const [queryPageIndex, setQueryPageIndex] = useState(0);
  const [queryPageSize, setQueryPageSize] = useState(10);

  let customerFilter = tableCurrentState.filters;

  // let customerQueryFilter = queryFilter?.display_name;

  let customerStageFilters = {
    stage: tableCurrentState.customer_stage,
  };

  let needsActionFilter = {
    need_actions: tableCurrentState.needs_action,
  };

  let NeedsActionFilter = tableCurrentState.needs_action
    ? Object.keys(tableCurrentState.needs_action).filter(
        (i) => needsActionFilter.need_actions[i]
      )
    : [];

  let FilteredCustomerStage = customerStageFilters.stage
    ? Object.keys(customerStageFilters.stage).filter(
        (i) => customerStageFilters.stage[i]
      )
    : [];
  let organizationId = localStorage.getItem("currOrgId");

  let filetedList;

  if (appAccessRest.master_filter_type == "BUSINESS_UNIT") {
    // resetForm();
    filetedList = Object.fromEntries(
      Object.entries(
        tableCurrentState?.filters ? tableCurrentState?.filters : {}
      ).filter(([key, value]) => key !== "business_unit")
    );
  } else if (
    appAccessRest.master_filter_type == "TEAM" ||
    appAccessRest.master_filter_type == "SELF"
  ) {
    // resetForm();
    filetedList = Object.fromEntries(
      Object.entries(
        tableCurrentState?.filters ? tableCurrentState?.filters : {}
      ).filter(([key, value]) => key !== "rel_manager")
    );
  } else {
    filetedList = tableCurrentState?.filters;
  }

  let localPageSize = localStorage.getItem("customer_page_size");

  let overAllFilters = {
    pageSize: localPageSize ? localPageSize : tableCurrentState.pageSize,
    customerFilter,
    filters: {
      ...globalFilter,
      ...appAccessRest,
      ...(NeedsActionFilter ? { needs_actions: [...NeedsActionFilter] } : []),
      ...(FilteredCustomerStage ? { stage: [...FilteredCustomerStage] } : []),
      search_value: queryFilter?.display_name,
    },
  };

  // API is passing in useQuery
  const {
    data,
    isFetched,
    isError,
    isLoading,
    refetch,
    status,
    isIdle,
    isSuccess,
  } = useQuery(
    [
      `${CUSTOMER_OVERVIEW}-${currentOrganization}`,
      {
        pageIndex: tableCurrentState.pageIndex + 1,
        ...(currentDefaultFormatDetails.en_sort
          ? {
              sortBy:
                tableCurrentState.sort && tableCurrentState.sort[0]
                  ? tableCurrentState.sort[0].desc
                    ? "-" + tableCurrentState.sort[0].id
                    : tableCurrentState.sort[0].id
                  : "",
            }
          : {}),

        ...overAllFilters,
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters, customerFilter } =
        queryKey[1];

      let newFilter = dynamicQueryFilter({
        gridStructure: gridStructure,
        timeZone: currentDefaultFormatDetails.time_zone,
        customerFilter: customerFilter,
      });
      return await customerData({
        organization: currentOrganization,
        pageIndex,
        sortBy,
        pageSize,
        filters,
        pg_type: "R",
        customerFilter: newFilter,
      });
    },
    {
      enabled: gridAndViewLoaded,
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    }
  );

  const getCustomerCount = useQuery(
    [
      `${CUSTOMER_OVERVIEW_COUNT}-${currentOrganization}`,
      {
        ...overAllFilters,
      },
    ],
    async ({ queryKey }) => {
      let { pageIndex, sortBy, pageSize, filters, customerFilter } =
        queryKey[1];

      let newFilter = dynamicQueryFilter({
        gridStructure: gridStructure,
        // customerQueryFilter: customerQueryFilter,
        timeZone: currentDefaultFormatDetails.time_zone,
        customerFilter: customerFilter,
        // fieldName: "display_name",
      });
      return await customerData({
        organization: currentOrganization,
        // pageIndex,
        sortBy,
        pageSize,
        pg_type: "C",
        filters,
        customerFilter: newFilter,
      });
    },
    {
      // enabled: status === "success" ? true : false,
      enabled: gridAndViewLoaded && isSuccess && !isIdle,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  let invoiceTitle;
  if (getType === "ADD-INV") {
    invoiceTitle = "add_invoice";
  }
  if (getType === "ADD-REC") {
    invoiceTitle = "add_recurring_invoice";
  }
  if (getType === "EDIT-INV") {
    invoiceTitle = "edit_invoice";
  }
  if (getType === "EDIT-SUB") {
    invoiceTitle = "edit_recurring";
  }
  if (getType === "ADD-CHARGE") {
    invoiceTitle = "add_charge";
  }
  if (getType === "EDIT-CHARGE") {
    invoiceTitle = "edit_charge";
  }

  return (
    <Layout>
      {getInvoiceParams ? (
        <>
          <div className="content-container">
            <div className="content-header">
              <div className="content-header__title">
                <PageTitle id={invoiceTitle} />
              </div>
              <div className="content-header__actions"></div>
            </div>
            <div className="content-body">
              <AddNewInvoicingForm
                selectedInvoiceId={selectedInvoiceId}
                setSelectedCustomerId={setSelectedCustomerId}
              />
              <DMSystem
                resourceName="customerId"
                resourceId={selectedCustomerId}
                documentData={""}
                createDocument={createDocument}
                deleteDocumentMethod={""}
                editDocumentMethod={""}
                entity={"customer"}
                drawerOpen={addingInvoiceDrawerState}
                documentDrawertype={addingInvoiceType}
                documentDrive={addingInvoiceDocumentData}
                openingDrawerState={documentInvoiceAddingDrawerState}
                closeDocmentDrawer={documentInvoiceAddingDrawerState}
              />
            </div>
          </div>
        </>
      ) : !getParams ? (
        <div className="content-container --layout_relative">
          <RoleAccessSelect pageIndex={setCustomerSummaryPageIndex} />
          <div className="content-header">
            <div className="content-header__title">
              <PageTitle id="customer_summary" />
            </div>
            <div className="content-header__actions" style={{ gap: "5px" }}>
              {currentDefaultFormatDetails.accounting_book === "CSV" && (
                <RBACWrapper role={ADD_CUSTOMER} type={"PERMISSION"}>
                  <AddCustomer />
                </RBACWrapper>
              )}
              <RBACWrapper role={BULK_RM_WORKFLOW} type="PERMISSION">
                <AssignData
                  pageIndex={tableCurrentState.pageIndex + 1}
                  pageSize={
                    localPageSize ? localPageSize : tableCurrentState.pageSize
                  }
                  queryFilter={{
                    ...globalFilter,
                    ...appAccessRest,
                    ...(NeedsActionFilter
                      ? { needs_actions: [...NeedsActionFilter] }
                      : []),
                    ...(FilteredCustomerStage
                      ? { stage: [...FilteredCustomerStage] }
                      : []),
                    search_value: queryFilter.display_name,
                  }}
                  querySortBy={
                    currentTableState.sort && currentTableState.sort[0]
                      ? currentTableState.sort[0].desc
                        ? "-" + currentTableState.sort[0].id
                        : currentTableState.sort[0].id
                      : null
                  }
                  service={customerData}
                  filteredColumns={filteredColumns}
                  allColumns={allColumns}
                  fileName={"Customer Summary"}
                  rowData={data && data.data && data.data.docs}
                  defaultCurrency={
                    currentDefaultFormatDetails &&
                    currentDefaultFormatDetails.default_currency
                  }
                  customerFilter={customerFilter}
                />
              </RBACWrapper>

              {/* <TableViewPicker
                allColumns={[]}
                tableDraftView={tableDraftView}
                viewName={viewName}
                menu={"customer_summary"}
                setView={setView}
              /> */}
              {/* <DownloadOverview />
               */}
              {/* <RBACWrapper type="PERMISSION"  role={ [{ DOWNLOAD_DIALER_LIST}&& {DOWNLOAD_CUSTOMER_LISIT}] } > */}
              <DownloadSummary
                queryFilter={{ ...queryFilter, ...appAccessRest }}
                querySortBy={
                  currentTableState.sort && currentTableState.sort[0]
                    ? currentTableState.sort[0].desc
                      ? "-" + currentTableState.sort[0].id
                      : currentTableState.sort[0].id
                    : null
                }
                service={customerData}
                filteredColumns={filteredColumns}
                fileName={"Customer Summary"}
                defaultCurrency={
                  currentDefaultFormatDetails &&
                  currentDefaultFormatDetails.default_currency
                }
                gridStructure={gridStructure}
              />
              {/* </RBACWrapper> */}
            </div>
          </div>
          <div className="content-body">
            <CustomerOverviewTable
              queryFilter={queryFilter}
              service={customerData}
              setQueryFilter={setQueryFilter}
              data={data}
              isFetched={isFetched}
              isError={isError}
              queryPageIndex={queryPageIndex}
              setQueryPageIndex={setQueryPageIndex}
              queryPageSize={queryPageSize}
              setQueryPageSize={setQueryPageSize}
              setQuerySortBy={setQuerySortBy}
              isLoading={isLoading}
              refetch={refetch}
              status={status}
              getCustomerCount={getCustomerCount}
            />
          </div>
        </div>
      ) : (
        <div className="cp__table__overview__wraper">
          <CustomerListTable
            getParams={getParams}
            tableData={data && data.data && data.data.docs}
            setQueryFilter={setQueryFilter}
            queryFilter={queryFilter}
          />
          <CustomerOverview
            getParams={getParams}
            setGetSearchParams={setGetSearchParams}
            tableData={data}
            setSelectedInvoiceId={setSelectedInvoiceId}
            cusSummaryrefetch={refetch}
          />
        </div>
      )}
    </Layout>
  );
}

export default CustomerSummary;
