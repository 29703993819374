import React, { forwardRef } from "react";
import AlertIcon from "../assets_v2/icons/AlertIcon.js";
import AlertClock from "../assets_v2/icons/AlertClock.js";
import AlertCircleSvg from "../assets/img/svg/AlertCircleSvg.js";
import AddressStatus from "../assets_v2/icons/AddressStatus.js";
import Auto from "../assets_v2/icons/Auto.js";
import BlupIcon from "../assets_v2/icons/BlupIcon.js";
import BackArrow from "../assets_v2/icons/BackArrow.js";
import Call from "../assets_v2/icons/Call.js";
import Card from "../assets_v2/icons/Card.js";
import CallBack from "../assets_v2/icons/CallBack.js";
import CallOutLine from "../assets/img/svg/CallOutLine.js";
import CallRecording from "../assets_v2/icons/CallRecording.js";
import CallUnsuccessfull from "../assets_v2/icons/CallUnsuccessfull.js";
import ChevronDown from "../assets_v2/icons/ChevronDown.js";
import ChevronLeft from "../assets_v2/icons/ChevronLeft.js";
import ChevronRight from "../assets_v2/icons/ChevronRight.js";
import CustomerPortal from "../assets_v2/icons/CustomerPortal.js";
import Comment from "../assets_v2/icons/Comment.js";
import Copy from "../assets_v2/icons/Copy.js";
import ContactUpdate from "../assets_v2/icons/ContactUpdate.js";
import ContactStatus from "../assets_v2/icons/ContactStatus.js";
import ChevronLiftCircle from "../assets_v2/icons/ChevronLiftCircle.js";
import ChevronRightCircle from "../assets_v2/icons/ChevronRightCircle.js";
import ChevronUp from "../assets_v2/icons/ChevronUp.js";
import CheckInInvSvg from "../assets/img/svg/CheckInInvSvg.js";
import CheckTick from "../assets/img/svg/CheckTick.js";
import Clock from "../assets_v2/icons/Clock.js";
import Dispute from "../assets_v2/icons/Dispute.js";
import Download from "../assets_v2/icons/Download.js";
import Delete from "../assets_v2/icons/Delete.js";
import Document from "../assets_v2/icons/Document.js";
import Dot from "../assets_v2/icons/Dot.js";
import Edit from "../assets_v2/icons/Edit.js";
import Email from "../assets_v2/icons/Email.js";
import EmailStatus from "../assets_v2/icons/EmailStatus.js";
import EmailOutine from "../assets/img/svg/EmailOutline.js";
import Enlarge from "../assets_v2/icons/Enlarge.js";
import ExpandLeft from "../assets_v2/icons/ExpandLeft.js";
import ExpandRight from "../assets_v2/icons/ExpandRight.js";
import FieldVisit from "../assets_v2/icons/FieldVisit.js";
import Flag from "../assets_v2/icons/Flag.js";
import GenericAction from "../assets_v2/icons/GenericAction.js";
import HoldAction from "../assets_v2/icons/HoldAction.js";
import InvoiceUpdate from "../assets_v2/icons/InvoiceUpdate.js";
import Letter from "../assets_v2/icons/Letter.js";
import LetterStatus from "../assets_v2/icons/LetterStatus.js";
import MinusOutline from "../assets_v2/icons/MinusOutline.js";
import Notes from "../assets_v2/icons/Notes.js";
import NextAction from "../assets_v2/icons/NextAction.js";
import Pay from "../assets_v2/icons/Pay.js";
import Pause from "../assets_v2/icons/Pause.js";
import Payment from "../assets_v2/icons/Payment.js";
import PaymentPlan from "../assets_v2/icons/PaymentPlan.js";
import PhoneStatus from "../assets_v2/icons/PhoneStatus.js";
import Pin from "../assets_v2/icons/Pin.js";
import Plus from "../assets_v2/icons/Plus.js";
import Recurring from "../assets_v2/icons/Recurring.js";
import ReleaseAction from "../assets_v2/icons/ReleaseAction.js";
import RelManager from "../assets_v2/icons/RelManager.js";
import Reschedule from "../assets_v2/icons/Reschedule.js";
import ResumeAction from "../assets_v2/icons/ResumeAction.js";
import ReviewPromise from "../assets_v2/icons/ReviewPromise.js";
import Remove from "../assets_v2/icons/Remove.js";
import RequestInfo from "../assets_v2/icons/RequestInfo.js";
import Settings from "../assets_v2/icons/Settings.js";
import SkipAction from "../assets_v2/icons/SkipAction.js";
import SkippedTimeLine from "../assets_v2/icons/SkippedTimeLine.js";
import SMS from "../assets_v2/icons/SMS.js";
import SMSStatus from "../assets_v2/icons/SMSStatus.js";
import UnPin from "../assets_v2/icons/UnPin.js";
import WhatsApp from "../assets_v2/icons/WhatsApp.js";
import WhatsAppStatus from "../assets_v2/icons/WhatsAppStatus.js";
import PromisePay from "../assets_v2/icons/PromisePay.js";
import BusinessUnit from "../assets_v2/icons/BusinessUnit.js";
import Workflow from "../assets_v2/icons/Workflow.js";
import Tick from "../assets_v2/icons/Tick.js";
import TripleDot from "../assets_v2/icons/TripleDot.js";
import Grid from "../assets_v2/icons/Grid.js";
import WorkflowHeigh from "../assets_v2/icons/WorkflowHeigh.js";
import Replay from "../assets_v2/icons/Replay.js";
import ReplayAll from "../assets_v2/icons/ReplayAll.js";
import Forward from "../assets_v2/icons/Forward.js";
import LegalAction from "../assets_v2/icons/LegalAction.js";
import SearchOutline from "../assets/img/svg/SearchOutline.js";
import DeleteOutline from "../assets/img/svg/DeleteOutline.js";
import Manualcall from "../assets_v2/icons/Manualcall.js";
import LandlineOuline from "../assets/img/svg/LandlineOuline.js";
import EmailOutline from "../assets/img/svg/EmailOutline.js";
import EditOutline from "../assets_v2/icons/EditOutline.js";
import DownloadOutline from "../assets/img/svg/DownloadOutline.js";
import UpdateAgo from "../assets/img/svg/UpdateAgo.js";
import PlayIcon from "../assets/img/svg/PlayIcon.js";
import DownArrow from "../utils/DownArrow.js";
import UserOutline from "../assets/img/svg/UserOutline.js";

import LocationOutline from "../assets/img/svg/LocationOutline.js";
import SuitecaseSvg from "../assets/img/svg/SuitecaseSvg.js";
import TermsIconSvg from "../assets/img/svg/TermsIconSvg.js";
import TabCollapseIcon from "../assets/img/svg/TabCollapseIcon.js";
import GroupStarSvg from "../assets/img/svg/GroupStarSvg.js";
import QuestionMarkCircle from "../assets/img/svg/QuestionMarkCircle.js";
import ConsumerSvg from "../assets/img/svg/ConsumerSvg.js";
import Upload from "../assets_v2/icons/Upload.js";
import UpdatedClock from "../assets_v2/icons/UpdatedClock.js";
import ViewIcon from "../assets/img/svg/ViewIcon.js";
import ViewIconOutline from "../assets_v2/icons/ViewIconOutline.js";
import Bankruptcy from "../assets_v2/icons/Bankruptcy.js";
import MinimizeIconSvg from "../assets/img/svg/MinimizeIconSvg.js";
import ContactChatIcon from "../assets_v2/icons/contactChatIcon.js";
import CrosssSvg from "../assets_v2/icons/CrosssSvg.js";
import SepartorContact from "../assets_v2/icons/SepartorContact.js";
import LockedSvg from "../assets_v2/icons/LockedSvg.js";
import NewUserOutline from "../assets/img/svg/NewUserOutline.js";
import { LockedPoper } from "../assets_v2/icons/LockedPoper.js";
import SuccessfullTick from "../assets/img/svg/SuccessfullTick.js";
import DeletedIcon from "../assets/img/svg/deletedIcon.js";
// import MasterGroupSvg from "../assets/img/svg/MasterGroupSvg.js";
import Batch from "../assets_v2/icons/Batch.js";
import IDCard from "../assets_v2/icons/IDCard.js";
import DateOfBirth from "../assets_v2/icons/DateOfBirth.js";
import ManualGroupingSvg from "../assets_v2/icons/ManualGroupingSvg.js";
import MasterGroupSvg from "../assets_v2/icons/MasterGroupSvg.js";
import CreditReport from "../assets_v2/icons/CreditReport.js";
import StickyNote from "../assets_v2/icons/StickyNote.js";
import NoteOutline from "../assets_v2/icons/NoteOutline.js";
import Minimize from "../assets/img/svg/Minimize.js";
import ArrowRight from "../assets_v2/icons/ArrowRight.js";
import Calendar from "../assets/img/svg/Calendar.js";
import InformativeIcon from "../assets/img/svg/InformativeIcon.js";
import PayCardOutline from "../assets_v2/icons/payCardOutline";
import FormulaIndicatorSvg from "../assets_v2/icons/FormulaIndicatorSvg.js";
import InfoIconFilled from "../assets/img/svg/InfoIconFilled.js";
import Referesh from "../assets_v2/icons/Referesh.js";
import MainDefaultAdhocSvg from "../assets/img/svg/MainDefaultAdhocSvg.js";
import DoNotDisturb from "../assets_v2/icons/DoNotDisturb.js";
import SMSOutlineSvg from "../assets/img/svg/SmsOutline.js";
import AdjustMent from "../assets_v2/icons/AdjustMent.js";
import CopySvg from "../assets_v2/icons/CopySvg.js";
import CustomizeColumn from "../assets_v2/CustomizeColumn.js";
import RuleIcon from "../assets_v2/icons/RuleIcon.js";
import Dashboard from "../assets_v2/icons/Dashboard.js";
import ConsumersG from "../assets_v2/icons/ConsumersG.js";
import AnalyticsRepo from "../assets_v2/icons/AnalyticsRepo.js";
import Invoice from "../assets_v2/icons/Invoice.js";
import CSVUpload from "../assets_v2/icons/CSVUpload.js";
import Notification from "../assets_v2/icons/Notification.js";
import Profile from "../assets_v2/icons/Profile.js";
import Help from "../assets_v2/icons/Help.js";
import Misc from "../assets_v2/icons/Misc.js";
import CallPlusOutline from "../assets_v2/icons/CallPlusOutline.js";
import SuitcaseOutlineSvg from "../assets/img/svg/SuitcaseSvg.js";
import CreditNotes from "../assets_v2/icons/CreditNotes.js";
import EsignSvg from "../assets/img/svg/EsignSvg.js";
import CallBulk from "../assets_v2/icons/CallBulk.js";
import VoiceBotIcon from "../assets/img/svg/VoiceBotIcon.js";
import NavigateIcon from "../assets_v2/icons/NavigateIcon.js";

// import MasterGroupSvg from "../assets/img/svg/MasterGroupSvg.js";

const Icon = forwardRef(function Icon(props, ref) {
  const {
    icon,
    color = "#333860",
    size = 22,
    style = {},
    className = "",
    isPressable = false,
    ...rest
  } = props;

  const IconSvg = iconRegistry[icon];

  return (
    <div
      ref={ref}
      style={{
        ...(style && typeof style === "object" ? style : {}),
      }}
      className={`mx-icon ${className} ${isPressable ? "mx-icon-button" : ""}`}
      {...rest}
    >
      {IconSvg ? (
        <IconSvg size={size} color={color} />
      ) : (
        <div
          style={{
            width: size,
            height: size,
            backgroundColor: color,
            overflow: "hidden",
          }}
        >
          {icon}
        </div>
      )}
    </div>
  );
});

const iconRegistry = {
  action_skipped: (props) => <SkippedTimeLine {...props} />,
  address_status: (props) => <AddressStatus {...props} />,
  adjustment: (props) => <AdjustMent {...props} />,
  alert_clock: (props) => <AlertClock {...props} />,
  alert: (props) => <AlertIcon {...props} />,
  alert_circle: (props) => <AlertCircleSvg {...props} />,
  arrow_right: (props) => <ArrowRight {...props} />,
  analytics: (props) => <AnalyticsRepo {...props} />,
  auto: (props) => <Auto {...props} />,
  bankruptcy: (props) => <Bankruptcy {...props} />,
  business_unit: (props) => <BusinessUnit {...props} />,
  back_arrow: (props) => <BackArrow {...props} />,
  batch: (props) => <Batch {...props} />,
  light_bulb: (props) => <BlupIcon {...props} />,
  consumers: (props) => <ConsumersG {...props} />,
  calendar: (props) => <Calendar {...props} />,
  call: (props) => <Call {...props} />,
  call_bulk: (props) => <CallBulk {...props} />,
  manual_call_outLine: (props) => <Manualcall {...props} />,
  call_outline: (props) => <CallOutLine {...props} />,
  email_outline: (props) => <EmailOutine {...props} />,
  card: (props) => <Card {...props} />,
  callback_later: (props) => <CallBack {...props} />,
  rule_icon: (props) => <RuleIcon {...props} />,
  call_plus_outline: (props) => <CallPlusOutline {...props} />,
  call_recording: (props) => <CallRecording {...props} />,
  comment_sep: (props) => <SepartorContact {...props} />,
  call_unsuccessfull: (props) => <CallUnsuccessfull {...props} />,
  chevron_down: (props) => <ChevronDown {...props} />,
  chevron_left: (props) => <ChevronLeft {...props} />,
  chevron_left_circle: (props) => <ChevronLiftCircle {...props} />,
  chevron_right: (props) => <ChevronRight {...props} />,
  chevron_right_circle: (props) => <ChevronRightCircle {...props} />,
  chevron_up: (props) => <ChevronUp {...props} />,
  clock: (props) => <Clock {...props} />,
  credit_report: (props) => <CreditReport {...props} />,
  customize_column: (props) => <CustomizeColumn {...props} />,
  credit_note: (props) => <CreditNotes {...props} />,
  updated_clock: (props) => <UpdatedClock {...props} />,
  comment: (props) => <Comment {...props} />,
  check_in: (props) => <CheckTick {...props} />,
  crosss: (props) => <CrosssSvg {...props} />,
  copy_svg_outline: (props) => <CopySvg {...props} />,
  copy: (props) => <Copy {...props} />,
  contacts: (props) => <ContactUpdate {...props} />,
  contacts_status: (props) => <ContactStatus {...props} />,
  customer_portal: (props) => <CustomerPortal {...props} />,
  consumer_group: (props) => <ConsumerSvg {...props} />,
  csv_upload: (props) => <CSVUpload {...props} />,
  dashboard: (props) => <Dashboard {...props} />,
  dispute: (props) => <Dispute {...props} />,
  download: (props) => <Download {...props} />,
  download_outline: (props) => <DownloadOutline {...props} />,
  delete: (props) => <Delete {...props} />,
  delete_icon: (props) => <DeletedIcon {...props} />,
  delete_outline: (props) => <DeleteOutline {...props} />,
  document: (props) => <Document {...props} />,
  download_outline: (props) => <DownloadOutline {...props} />,
  down_arrow: (props) => <DownArrow {...props} />,
  dob: (props) => <DateOfBirth {...props} />,
  dot: (props) => <Dot {...props} />,
  dnd: (props) => <DoNotDisturb {...props} />,
  edit: (props) => <Edit {...props} />,
  eSign: (props) => <EsignSvg {...props} />,
  edit_outline: (props) => <EditOutline {...props} />,
  email: (props) => <Email {...props} />,
  contact_chat_icon: (props) => <ContactChatIcon {...props} />,
  email_outline: (props) => <EmailOutline {...props} />,
  email_status: (props) => <EmailStatus {...props} />,
  enlarge: (props) => <Enlarge {...props} />,
  expand_left: (props) => <ExpandLeft {...props} />,
  expand_right: (props) => <ExpandRight {...props} />,
  field_visit: (props) => <FieldVisit {...props} />,
  forward: (props) => <Forward {...props} />,
  formula_indicator: (props) => <FormulaIndicatorSvg {...props} />,
  flag: (props) => <Flag {...props} />,
  generic_action: (props) => <GenericAction {...props} />,
  grid: (props) => <Grid {...props} />,
  group_star: (props) => <GroupStarSvg {...props} />,
  hold_action: (props) => <HoldAction {...props} />,
  help: (props) => <Help {...props} />,
  id_card: (props) => <IDCard {...props} />,
  question_circle: (props) => <QuestionMarkCircle {...props} />,
  invoices: (props) => <Invoice {...props} />,
  info_icon: (props) => <InformativeIcon {...props} />,
  info_icon_filled: (props) => <InfoIconFilled {...props} />,
  invoice_update: (props) => <InvoiceUpdate {...props} />,
  letter: (props) => <Letter {...props} />,
  landline_outline: (props) => <LandlineOuline {...props} />,
  letter_status: (props) => <LetterStatus {...props} />,
  lockedIcon: (props) => <LockedSvg {...props} />,
  lockedPoper: (props) => <LockedPoper {...props} />,
  legal_action: (props) => <LegalAction {...props} />,
  location_outline: (props) => <LocationOutline {...props} />,
  note: (props) => <Notes {...props} />,
  note_outline: (props) => <NoteOutline {...props} />,
  next_action: (props) => <NextAction {...props} />,
  misc: (props) => <Misc {...props} />,
  minus_outline: (props) => <MinusOutline {...props} />,
  minimize: (props) => <Minimize {...props} />,
  minimize_icon: (props) => <MinimizeIconSvg {...props} />,
  master_group: (props) => <MasterGroupSvg {...props} />,
  manual_group: (props) => <ManualGroupingSvg {...props} />,
  maintain_adhoc_upload: (props) => <MainDefaultAdhocSvg {...props} />,
  note: (props) => <Notes {...props} />,
  navigate_icon: (props) => <NavigateIcon {...props} />,
  next_action: (props) => <NextAction />,
  notification: (props) => <Notification {...props} />,
  view_icon: (props) => <ViewIconOutline {...props} />,
  user_outLine: (props) => <UserOutline {...props} />,
  new_user_outLine: (props) => <NewUserOutline {...props} />,
  profile: (props) => <Profile {...props} />,
  pay: (props) => <Pay {...props} />,
  pause: (props) => <Pause {...props} />,
  payment: (props) => <Payment {...props} />,
  payment_plan: (props) => <PaymentPlan {...props} />,
  phone_status: (props) => <PhoneStatus {...props} />,
  pin: (props) => <Pin {...props} />,
  pay_card_outline: (props) => <PayCardOutline {...props} />,
  plus: (props) => <Plus {...props} />,
  play_svg: (props) => <PlayIcon {...props} />,
  promise_to_pay: (props) => <PromisePay {...props} />,
  recurring: (props) => <Recurring {...props} />,
  rel_manager: (props) => <RelManager {...props} />,
  release_action: (props) => <ReleaseAction {...props} />,
  resume_action: (props) => <ResumeAction {...props} />,
  reschedule: (props) => <Reschedule {...props} />,
  request_info: (props) => <RequestInfo {...props} />,
  replay: (props) => <Replay {...props} />,
  replay_all: (props) => <ReplayAll {...props} />,
  review_promise: (props) => <ReviewPromise {...props} />,
  referesh: (props) => <Referesh {...props} />,
  remove: (props) => <Remove {...props} />,
  settings: (props) => <Settings {...props} />,
  skip_action: (props) => <SkipAction {...props} />,
  suite_case: (props) => <SuitecaseSvg {...props} />,
  suite_case_outline: (props) => <SuitcaseOutlineSvg {...props} />,

  sms: (props) => <SMS {...props} />,
  sms_outline: (props) => <SMSOutlineSvg {...props} />,
  search_outline: (props) => <SearchOutline {...props} />,
  sms_status: (props) => <SMSStatus {...props} />,
  successfull: (props) => <SuccessfullTick {...props} />,
  sticky_note: (props) => <StickyNote {...props} />,
  tick: (props) => <Tick {...props} />,
  tabCollapse: (props) => <TabCollapseIcon {...props} />,
  terms_outline: (props) => <TermsIconSvg {...props} />,
  triple_dot: (props) => <TripleDot {...props} />,
  unpin: (props) => <UnPin {...props} />,
  upload: (props) => <Upload {...props} />,
  update_ago: (props) => <UpdateAgo {...props} />,
  voice_bot_status: (props) => <VoiceBotIcon {...props} />,
  workflow: (props) => <Workflow {...props} />,
  whatsapp: (props) => <WhatsApp {...props} />,
  whatsapp_status: (props) => <WhatsAppStatus {...props} />,
  workflow_heigh: (props) => <WorkflowHeigh {...props} />,
};

export default Icon;
