import {
  GET_INVOICE_GRID_STRUCTURE,
  UPDATE_INVOICE_TABLE_DRAFT_STATE,
  SET_INVOICE_VIEW,
  CHANGING_DUE_AMOUNT,
  CHANGING_ALLOCATED_AMOUNT,
  INITIAL_INVOICE_SUMMARY,
  SET_INV_GRID_AND_VIEW,
  SET_INV_PAGE_INDEX,
  SET_INV_PAGE_SIZE,
  SET_INV_SORT,
  INVOICE_FILTER,
  SET_INV_FILTER,
  RESET_FORM_DATA,
  SET_GLOBAL_INVOICE_FILTER,
  INV_DASHBOARD_NEED_ACTION_FILTER, // fix
  INV_NEEDS_ACTION_FILTERS,
  TABLE_WIDTH,
  SET_INV_PINNED_VIEW,
  INVOICE_DASHBOARD_FILTER,
} from "./action";
import columnMapper from "../../utils/mapper";
import tableStateDraftMapper from "../../utils/tableStateDraftMapper";

const initialQueryBuilder = {
  combinator: "and",
  rules: [],
};

const initialState = {
  gridStructure: [],
  pinnedView: [],
  width: 0,
  tableState: {
    hiddenColumns: [],
    columns: [],
    sort: null,
    filters: [],
  },
  tableCurrentState: {
    sort: null,
    pageIndex: 0,
    pageSize: 10,
    hiddenColumns: [],
    columns: [],
    sort: null,
    filters: {},
    globalFilters: "",
    formFilters: initialQueryBuilder,
    needs_action: {},
    customer_stage: {},
  },

  tableDraftView: {
    table: [],
    filters: {},
    formFilters: initialQueryBuilder,
  },
  viewName: null,

  columnsData: [],
  currentTableData: { columns: [], hiddenColumns: [] },
  actionType: "",
  gridAndViewLoaded: false,
  invoiceFilter: false,
  path: "",
};

/*** TABLE SETTINGS ***/
const isExpanded = false;
const isExpandedHeader = false;
const isSelection = true;

export const invoiceSummaryReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case TABLE_WIDTH: {
      return {
        ...state,
        width: payload,
      };
    }
    case SET_INV_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }
    case INVOICE_DASHBOARD_FILTER: {
      return {
        ...state,
        path: payload.path,
        tableCurrentState: {
          ...state.tableCurrentState,
          filters: payload.filters,
          formFilters: payload.formFilters,
        },
      };
    }

    case SET_GLOBAL_INVOICE_FILTER: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          globalFilters: payload,
        },
      };
    }

    case INVOICE_FILTER: {
      return {
        ...state,
        invoiceFilter: payload,
      };
    }
    case RESET_FORM_DATA: {
      return {
        ...state,
        // viewName: null,
        tableCurrentState: {
          ...state.currentTableData,
          pageIndex: 0,
          pageSize: 10,
          hiddenColumns: [],
          columns: [],
          sort: null,
          filters: {},
          globalFilters: "",
          formFilters: initialQueryBuilder,
          needs_action: {},
          customer_stage: {},
        },
      };
    }
    case INITIAL_INVOICE_SUMMARY: {
      return {
        ...state,
        ...initialState,
      };
    }

    case GET_INVOICE_GRID_STRUCTURE: {
      let gridStructure = { ...payload }; // {menu, table[], widget[]}

      let { hiddenColumns = [], columns = [] } = columnMapper({
        columns: gridStructure.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        width: state.width,
      });

      return {
        ...state,
        gridStructure,
        tableState: { hiddenColumns, columns },
      };
    }

    case SET_INVOICE_VIEW: {
      let newState = { ...state };
      let viewData = { ...payload };

      newState.tableState = columnMapper({
        columns: state.gridStructure.table,
        viewSelect: viewData.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        width: newState.width,
      });

      newState.viewName = viewData.name;

      newState.tableDraftView = initialState.tableDraftView;

      newState.gridAndViewLoaded = true;

      if (newState.tableState.sort) {
        let isDesc = newState.tableState.sort.startsWith("-");

        newState.tableCurrentState.sort = [
          { id: newState.tableState.sort.replace("-", ""), desc: isDesc },
        ];
      }

      if (newState.tableState.filters) {
        const currentFilter = newState.tableState.filters;

        // let flatGridStructure = newState.gridStructure.table.reduce(
        //   (prev, curr) => {
        //     if (curr.nested) {
        //       return [...prev, ...(curr?.nested ? curr?.nested : [])];
        //     }
        //     return [...prev, curr];
        //   },
        //   []
        // );
        // let formFilters = [];
        // let filters = currentFilter.reduce((prev, curr) => {
        //   //{curr ==== {col_id, filter.value}

        //   let currGSColumn = flatGridStructure.find(
        //     (i) => i.accessor === curr.id
        //   );
        //   if (currGSColumn) {
        //     formFilters.push({
        //       id: [
        //         {
        //           label: `${currGSColumn.literal_id}`,
        //           id: `${currGSColumn.literal_id}`,
        //         },
        //       ],
        //       type: currGSColumn.type,
        //       cellType: currGSColumn.cell_type,
        //       accessor: currGSColumn.accessor,
        //       value: curr.value,
        //     });
        //   }

        //   return { ...prev, [curr.id]: curr.value };
        // }, {});

        newState.tableCurrentState.formFilters = viewData.filter;
        newState.tableCurrentState.filters = viewData.filter;
        newState.tableCurrentState.needs_action = viewData.needs_action;
        newState.tableCurrentState.customer_stage = viewData.customer_stage;
      }

      return {
        ...newState,
      };
    }

    case SET_INV_GRID_AND_VIEW: {
      let newState = { ...state };

      let { grid_structure, default_view, pinned_view = [] } = payload;

      newState.gridStructure = grid_structure;
      newState.pinnedView = pinned_view;

      newState.tableState = columnMapper({
        columns: grid_structure?.table,
        viewSelect: default_view?.table,
        isExpanded,
        isExpandedHeader,
        isSelection,
        width: newState.width,
      });

      newState.viewName = default_view?.name;

      newState.tableDraftView = initialState.tableDraftView;

      newState.gridAndViewLoaded = true;

      if (newState.tableState.sort) {
        let isDesc = newState.tableState.sort.startsWith("-");
        newState.tableCurrentState.sort = [
          { id: newState.tableState.sort.replace("-", ""), desc: isDesc },
        ];
      }
      if (newState.tableState.filters) {
        const currentFilter = newState.tableState.filters;

        let flatGridStructure = newState.gridStructure.table.reduce(
          (prev, curr) => {
            if (curr.nested) {
              return [...prev, ...(curr?.nested ? curr?.nested : [])];
            }
            return [...prev, curr];
          },
          []
        );
        let formFilters = [];
        let filters = currentFilter.reduce((prev, curr) => {
          //{curr ==== {col_id, filter.value}

          let currGSColumn = flatGridStructure.find(
            (i) => i.accessor === curr.id
          );
          if (currGSColumn) {
            formFilters.push({
              id: [
                {
                  label: `${currGSColumn.literal_id}`,
                  id: `${currGSColumn.literal_id}`,
                },
              ],
              type: currGSColumn.type,
              cellType: currGSColumn.cell_type,
              accessor: currGSColumn.accessor,
              value: curr.value,
            });
          }

          return { ...prev, [curr.id]: curr.value };
        }, {});

        if (!state.path) {
          newState.tableCurrentState.formFilters = default_view?.filter;
          newState.tableCurrentState.filters = default_view?.filter;
          newState.tableCurrentState.needs_action = default_view?.needs_action;
          newState.tableCurrentState.customer_stage =
            default_view?.customer_stage;
        }
      }
      return {
        ...newState,
      };
    }

    case UPDATE_INVOICE_TABLE_DRAFT_STATE: {
      let draftColumnData = tableStateDraftMapper({
        tableColumns: payload.table,
        isSelection,
        isExpanded,
        isExpandedHeader,
      });

      let newState = { ...state };

      newState.tableDraftView = { table: draftColumnData };

      if (
        state.tableCurrentState.sort &&
        state.tableCurrentState.sort[0] &&
        payload.sort[0] &&
        payload.sort[0].id !== state.tableCurrentState.sort[0].id &&
        payload.sort[0].desc !== state.tableCurrentState.sort[0].desc &&
        !state.path
      ) {
        newState.tableCurrentState = {
          ...newState.tableCurrentState,
          sort: payload.sort,
          needs_action: newState?.tableCurrentState?.needs_action,
          customer_stage: newState?.tableCurrentState?.customer_stage,
          formFilters: newState?.tableCurrentState?.formFilters,
          filters: newState?.tableCurrentState?.filters,
        };
      }

      return {
        ...newState,
      };
    }

    case SET_INV_SORT: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          sort: payload,
        },
      };
    }

    case SET_INV_PAGE_INDEX: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          pageIndex: payload,
        },
      };
    }

    case SET_INV_PAGE_SIZE: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          pageSize: payload,
        },
      };
    }

    case INV_DASHBOARD_NEED_ACTION_FILTER: {
      let needActionType = payload;

      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          formFilters: initialState?.tableCurrentState?.formFilters,
          filters: initialState?.tableCurrentState?.filters,
          needs_action: {
            ...initialState?.tableCurrentState?.needs_action,
            [payload.key]: true,
          },
          customer_stage: initialState?.tableCurrentState?.customer_stage,
        },
        path: payload?.path,
      };
    }

    case INV_NEEDS_ACTION_FILTERS: {
      return {
        ...state,
        tableCurrentState: {
          ...state.tableCurrentState,
          needs_action: payload,
        },
      };
    }

    case SET_INV_PINNED_VIEW: {
      return {
        ...state,
        pinnedView: payload,
      };
    }

    default:
      return state;
  }
};
